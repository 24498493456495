import React from "react";


const Money = ({ value }) => {
  let cash = value.toLocaleString("en-US");
  return (
    <div className="mb-4">
      <div className="text-center text-orange c-h2">
        ${cash}
      </div>
      <div className="text-center text-white">
        total donated due to your social sharing
      </div>
    </div>
  );
};

export default Money;
