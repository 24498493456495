import React from "react";

// Impact Images
import Carbon from "../../images/impact-images/carbon.svg";
import Trees from "../../images/impact-images/trees.svg";

const Impact = ({ image, value, description }) => {
  let finalImage = <Carbon className="hidden md:inline-block"/>
  switch (image) {
    case 'trees':
      finalImage = <Trees className="hidden md:inline-block"/>
  }

  return (
    <div className="rounded-xl bg-light-purple mx-4 px-4 py-4 w-80 h-48">
      <div className="center-text h-24">
        {finalImage}
      </div>
      <div className="center-text c-h3">
        <h1>{value}</h1>
      </div>
      <div className="center-text">
        <h1>{description}</h1>
      </div>
    </div>
  );
};

export default Impact;
